import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.listItemsFiltered ? _vm.listItemsFiltered.length : false)?_c('div',[_c(VMenu,{attrs:{"open-on-hover":_vm.openOnHover,"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":!_vm.btn}},'v-btn',_vm.componentProps,false),_vm.eventHandlers),[(_vm.activatorIcon)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.activatorIcon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.btn ? _vm.label : '')+" "),(_vm.iconRight)?_c(VIcon,{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.iconRight)+" ")]):_vm._e()],1)],1)]}}],null,false,1039013266)},[_c(VList,[_c('div',{on:{"!click":function($event){return _vm.$emit('bind-actions-item', _vm.item)}}},_vm._l((_vm.listItemsFiltered),function(listItem,index){return _c(VListItem,_vm._g(_vm._b({key:((listItem.label) + "-" + index),attrs:{"color":listItem.color}},'v-list-item',listItem.componentProps,false),listItem.eventHandlers),[_c(VListItemTitle,[(listItem.icon)?_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.getDataWithKey(listItem, 'icon'))+" ")]):_vm._e(),_c('span',{class:((listItem.color) + "--text")},[_vm._v(" "+_vm._s(_vm.getDataWithKey(listItem, 'text'))+" ")])],1)],1)}),1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }