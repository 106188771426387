import Swal from 'sweetalert2'

export const toastSuccess = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'success',
    title: message,
  })
}
export const toastInfo = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'info',
    title: message,
  })
}
export const toastFail = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'error',
    title: message,
  })
}

export const toastWait = (waitPromise, message) => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 1000,
  })

  Toast.fire({
    // title: message,
    icon: 'info',
    html: `<div>${message || '处理中，请稍等...'}</div>`,
  })

  Toast.stopTimer()

  return new Promise((resolve, reject) => {
    waitPromise.then(res => {
      Toast.resumeTimer()
      resolve(res)
    }).catch(err => {
      Toast.resumeTimer()
      reject(err)
    })
  })
}

export const modalSuccess = message => Swal.fire(
  '成功!',
  message,
  'success',
)
export const modalFail = message => Swal.fire(
  '失败!',
  message || '未知错误，请联系管理员.',
  'error',
)

export const modalEnquire = message => Swal.fire({
  title: '请确认所填信息无误',
  text: message,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: '是的，我意已决!',
  cancelButtonText: '不，让我再想想',
})

export const modalChose = title => Swal.fire({
  title,
  icon: 'warning',
  iconColor: 'red',
  showCancelButton: true,
  confirmButtonColor: '#7066DF',
  cancelButtonColor: '#636C74',
  confirmButtonText: '好的',
  cancelButtonText: '取消',
})

export const modalDisclaimers = (title, html) => Swal.fire({
  title,
  html,
  icon: 'info',
  showCancelButton: false,
  confirmButtonColor: '#7066DF',
  cancelButtonColor: '#636C74',
  confirmButtonText: '好的',
  cancelButtonText: '取消',
  showCloseButton: false,
  focusConfirm: false,
})

export const modalEnquireTitle = (title, message) => Swal.fire({
  title,
  text: message,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: '是的，我意已决!',
  cancelButtonText: '不，让我再想想',
})

export const modalEnquireBtn = (title, text, btnText) => Swal.fire({
  title: title || '请确认所填信息无误',
  text,
  confirmButtonText: btnText,
  width: 600,
  padding: '3em',
  color: '#716add',
  background: '#fff url("https://sweetalert2.github.io/images/trees.png")',
  backdrop: `
    rgba(0,0,123,0.4)
    url("https://sweetalert2.github.io/images/nyan-cat.gif")
    left top
    no-repeat
  `,
})

export const modalEnquireBtnHtml = (title, html, btnText, options = {}) => Swal.fire({
  title: title || '请确认所填信息无误',
  html,
  confirmButtonText: btnText,
  width: 600,
  padding: '1em',
  ...options,
})

// waitFunc 需要等待时得是一个返回Promise的函数，立即执行的就无所谓
export const modalEnquireWait = (waitFunc, title, text, noWait = false, funcArgs = []) => Swal.fire({
  title: title || '请确认所填信息无误',
  text,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: '是的，我意已决!',
  cancelButtonText: '不，让我再想想',
  showLoaderOnConfirm: true,
  preConfirm: () => new Promise((resolve, reject) => {
    try {
      resolve(waitFunc.call(null, ...funcArgs))
    } catch (e) {
      reject(e)
    }
  }),
  allowOutsideClick: () => noWait || !Swal.isLoading(),
  backdrop: () => noWait || !Swal.isLoading(),
})
