<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { modalEnquireBtnHtml } from '@core/utils/prompt'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    modalEnquireBtnHtml(
      '致小五用户的一封信',
      '<div style="text-align: left; font-weight: bold; padding-bottom: 10px; word-break: break-all; line-height: 30px;">亲爱的创作者朋友们：</div><div style="text-align: left; text-indent: 2em; line-height: 30px;">为了面向合作伙伴提供更优质的服务和产品，我们对系统进行了全新升级，新系统地址：<a href="https://cr.xiaowutube.com">cr.xiaowutube.com</a>，欢迎体验。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请收藏新地址以便于下次访问，原地址将于<strong>9月1日</strong>后无法继续访问，感谢您对小五的支持！</div>',
      '立即前往',
      {
        allowOutsideClick: false
      }
    ).then(res => {
      if (res.isConfirmed) {
        window.location.href = 'https://cr.xiaowutube.com/login'
      }
    })
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
