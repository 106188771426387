import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewHorizontalNavMenuHeaderGroup(_vm.item))?_c(VMenu,{ref:"refMenu",staticClass:"horizontal-nav-header-group",attrs:{"offset-y":"","eagers":"","open-on-hover":_vm.shallOpenGroupOnHover,"attach":"","content-class":"list-style elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({ref:"refActivator",staticClass:"text-capitalize text-base font-weight-regular px-6",class:[{'bg-gradient-primary group-activator-active': _vm.isActive}, { 'menu-open': _vm.isMenuActive }],attrs:{"color":_vm.isActive ? 'primary' : 'transparent',"depressed":"","large":"","rounded":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":"","left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.item.title))]),_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)]}}],null,false,2611580130)},[_c(VList,{ref:"refContent",attrs:{"elevation":_vm.$vuetify.theme.dark ? 9 : 8}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.item.children),function(child){return _c(_vm.resolveNavComponent(child),{key:child.subheader || child.title,tag:"component",attrs:{"item":child}})}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }